<template>
  <v-container fluid>
    <h1 class="display-1 ma-6">{{ title }}</h1>
    <v-tabs
      background-color="grey lighten-4"
      active-class="white"
      v-model="tab"
    >
      <v-tab
        v-for="item in tabsItem"
        :key="item.title"
      >
        <v-icon class="mr-3">{{ item.icon }}</v-icon>
        {{ item.title }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" >
      <v-tab-item
        v-for="item in tabsItem"
        :key="item.title"
      >
        <v-card>
          <v-card-text>
            <component v-bind:is="item.body"></component>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import DeliveryEquipmentForm from '../../../components/DeliveryEquipmentForm'

export default {
  name: 'DeliveryEquipment',
  components: { DeliveryEquipmentForm },
  data() {
    return {
      tab: null,
      title: 'Доставка оборудования',
      tabsItem: [
        {
          id: 1,
          title: 'Форма заявки',
          icon: 'mdi-file-document-edit',
          body: 'DeliveryEquipmentForm'
        }
      ]
    }
  }
}
</script>
